/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { Heading, DividerNew } from 'sarsaparilla';
import CommonFilter from '../common/CommonFilter';

import {
    CAMPING_TYPES,
    ELECTRIC_TYPE,
    VEHICLE_LENGTH_TYPE,
} from '../../../constants/filters/camping';

import { getCount, getGroupCount } from '../../../utils/filters';
import { CampingTypeFiltersProps } from '../../../types';
import NumberFieldFilter from '../common/NumberFieldFilter';

export function Filters({
    search,
    onApplyFilters,
    onChange,
    aggregations,
    filterEmptyFilters,
    clickTagCategory,
    onCriteriaUpdate,
    maxVehicleLength,
}: CampingTypeFiltersProps) {
    const hasDivider = filterEmptyFilters || false;
    const dividerClassname = !filterEmptyFilters ? 'pt-2' : '';
    return (
        <>
            <CommonFilter
                aggregations={aggregations}
                search={search}
                filters={CAMPING_TYPES}
                title="Camping Type"
                onChange={onChange}
                filterEmptyFilters={filterEmptyFilters}
                hasDivider={false}
                clickTagCategory={clickTagCategory}
            />
            <div className={dividerClassname}>
                <DividerNew />
            </div>
            <div className="pt-2">
                <NumberFieldFilter
                    label={VEHICLE_LENGTH_TYPE.value}
                    id={VEHICLE_LENGTH_TYPE.key}
                    units="feet"
                    max={maxVehicleLength ?? 99}
                    onApplyFilters={onApplyFilters}
                    filter={VEHICLE_LENGTH_TYPE.key}
                    unitAfter="ft"
                    aggregations={aggregations}
                    filterEmptyFilters={filterEmptyFilters}
                    clickTagCategory={clickTagCategory}
                    onCriteriaUpdate={onCriteriaUpdate}
                    search={search}
                />
            </div>
            <CommonFilter
                aggregations={aggregations}
                search={search}
                filters={ELECTRIC_TYPE}
                filterEmptyFilters={filterEmptyFilters}
                title="Electricity"
                onChange={onChange}
                hasDivider={hasDivider}
                clickTagCategory={clickTagCategory}
            />
        </>
    );
}

function CampsiteType({
    search,
    onApplyFilters,
    filterEmptyFilters,
    onChange,
    aggregations,
    clickTagCategory,
    onCriteriaUpdate,
}: CampingTypeFiltersProps) {
    const vehicleLengthCount = getCount(['campsiteVehicleLength'], search?.uiFilters);
    const filterKeys = [...CAMPING_TYPES, ...ELECTRIC_TYPE, VEHICLE_LENGTH_TYPE].map(
        (item) => item.key
    );
    const campingCount = vehicleLengthCount + getGroupCount(filterKeys, search);
    return (
        <div>
            <div className="search-filters-section-header rounded-layout mb-1 mt-4">
                <Heading headingLevel={2} appearance="h5">
                    Select the Camping filters that work for you.
                </Heading>
                <span>
                    {campingCount}{' '}
                    {campingCount > 1 || campingCount === 0 ? 'Filters' : 'Filter'}{' '}
                    Selected
                </span>
            </div>
            <div className="search-filters-filter-container">
                <Filters
                    aggregations={aggregations}
                    search={search}
                    onApplyFilters={onApplyFilters}
                    onChange={onChange}
                    filterEmptyFilters={filterEmptyFilters}
                    clickTagCategory={clickTagCategory}
                    onCriteriaUpdate={onCriteriaUpdate}
                />
            </div>
        </div>
    );
}

export default CampsiteType;
